<template>
  <transition name="width-transition" :css="$vuetify.breakpoint.lgAndUp">
    <v-col v-show="value" cols="12" lg="4" class="fill-height">
      <v-tabs
        v-if="!sidebarContentFakeLoader"
        v-model="sidebarContent"
        grow
        center-active
        class="fill-height d-flex flex-column rounded overflow-hidden"
      >
        <template v-for="(sidebarItem, index) in items">
          <v-tab :key="`sidebar-item-${index}`">
            <slot name="sidebar.title" :item-def="sidebarItem">
              {{ sidebarItem.title }}
            </slot>
          </v-tab>
        </template>

        <v-btn tile icon class="mr-2 mt-auto mb-auto" @click="closeSidebar">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-tabs-items v-model="sidebarContent" class="fill-height">
          <template v-for="(sidebarItem, index) in items">
            <v-tab-item :key="`sidebar-item-${index}`" class="pa-5">
              <slot :name="`${sidebarItem.name || index}`" :item="value" />
            </v-tab-item>
          </template>
        </v-tabs-items>
      </v-tabs>
      <template v-else>
        <v-card class="fill-height" flat>
          <v-skeleton-loader class="fill-height" loading boilerplate elevation="0" type="article, actions" />
        </v-card>
      </template>
    </v-col>
  </transition>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'AppSidebar',
  props: {
    value: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: _ => [],
    },
  },
  setup(props, { emit, root: { $vuetify } }) {
    const sidebarContent = ref(null)
    const sidebarContentFakeLoader = ref(true)
    const fakeLoaderTimeout = ref(null)

    watch(
      _ => props.value,
      (val, oldVal) => {
        if (oldVal === null || (val === null && $vuetify.breakpoint.lgAndUp)) {
          clearTimeout(fakeLoaderTimeout.value)
          sidebarContentFakeLoader.value = true
          fakeLoaderTimeout.value = setTimeout(_ => {
            sidebarContentFakeLoader.value = false
          }, 400)
        } else {
          clearTimeout(fakeLoaderTimeout.value)
          sidebarContentFakeLoader.value = false
        }
      },
    )

    const closeSidebar = _ => {
      emit('onCloseSidebar', null)
    }

    return {
      sidebarContent,
      sidebarContentFakeLoader,
      closeSidebar,
    }
  },
})
</script>

<style lang="scss" scoped>
.width-transition-enter-active {
  transition: all 0.3s ease;
}
.width-transition-leave-active {
  transition: all 0.3s ease;
}
.width-transition-enter,
.width-transition-leave-to {
  max-width: 0;
  opacity: 0;
}
</style>
