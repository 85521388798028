<template>
  <v-pagination v-model="page" :class="classes" :length="pagination.pageCount" :total-visible="totalVisible" />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AppPagination',
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    totalVisible: {
      type: Number,
      default: 7,
    },
    location: {
      type: String,
      default: 'bottom',
    },
  },
  setup(props, { root: { $router } }) {
    const page = computed({
      get: _ => props.pagination.page,
      set: val => {
        if (val !== props.pagination.page) {
          changePage(val)
        }
      },
    })

    const classes = computed(_ => props.location === 'bottom' ? 'mt-4' : 'mb-4 d-sm-flex d-md-none')

    const changePage = page => {
      $router.push({ query: { page } })
    }

    return {
      classes,
      page,
    }
  },
})
</script>
