<template>
  <v-container fluid fill-height class="pt-6">
    <v-row class="fill-height flex-lg-nowrap">
      <v-col
        cols="auto"
        class="fill-height flex-grow-1 width-transition-persistent"
        :class="{ 'hidden-md-and-down flex-grow-1 flex-grow-md-0 flex-shrink-1 flex-md-grow-1': value }"
      >
        <slot />
      </v-col>

      <AppSidebar :items="sidebarItems" :value="value" @onCloseSidebar="closeSidebar">
        <template v-for="sidebarItem in sidebarItems" #[sidebarItem.name]="{ item }">
          <slot v-if="sidebarItem.name" :name="`sidebar.${sidebarItem.name}`" :item="item" />
        </template>
      </AppSidebar>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import AppSidebar from '@/components/core/AppSidebar.vue'

export default defineComponent({
  name: 'OverviewWrapper',
  components: {
    AppSidebar,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    sidebarItems: {
      type: Array,
      default: _ => [],
    },
  },
  setup(props, { emit }) {
    const closeSidebar = _ => {
      emit('input', null)
    }

    return {
      closeSidebar,
    }
  },
})
</script>

<style lang="scss" scoped>
.width-transition-persistent {
  max-width: 100%;
  opacity: 1;
  transition: all 0.3s ease;
}
</style>
